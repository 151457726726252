import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const SubmissionDialog = (props) => {
  const { isOpen, setIsOpen } = props;

  return (
    isOpen && (
      <>
        <div
          className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 flex flex-col items-center justify-center"
        >
          {/* <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div> */}
            <FontAwesomeIcon icon={faSpinner} spin size="4x" className="text-brand-500 mb-4" />
          <h2 className="text-center text-white text-xl font-semibold">
            Submitting…
          </h2>
          <p className="w-1/3 text-center text-white">
            This may take a few seconds, please don't close this page.
          </p>
        </div>
      </>
    )
  );
};

export default SubmissionDialog;
