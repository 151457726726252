import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudShowersHeavy, faServer } from "@fortawesome/free-solid-svg-icons";

const ServerErrorDialog = (props) => {
    const { isOpen, setIsOpen } = props;
  
    return (
      isOpen && (
        <>
          <div
            className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 flex flex-col items-center justify-center"
          >

        
            <FontAwesomeIcon icon={faCloudShowersHeavy}  size="4x" className="text-blue-200 animate-wiggle mb-2" />
            <FontAwesomeIcon icon={faServer}  size="4x" className="text-brand-500 mb-8" />
            
            <h2 className="text-center text-white text-xl font-semibold">
              Sorry
            </h2>
            <p className="max-w-sm text-center text-white mb-4">
              Our server encountered a problem while processing your submission. Please try again, or contact the site administrators.
            </p>
            <div className="bg-brand-500 hover:bg-brand-600 rounded px-8 py-2 text-white" onClick={() => setIsOpen(false)}>OK</div>
          </div>
          
        </>
      )
    );
  };

export default ServerErrorDialog;