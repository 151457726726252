import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import axios from "axios";
import { Input, MySelect, YesNoRadio, FileUpload } from "./FormControls";
import { serialize } from "object-to-formdata";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckSquare,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./RegoForm.css";
import ReCAPTCHA from "react-google-recaptcha";
import SubmissionDialog from "./SubmissionDialog";
import SubmissionDoneMessage from "./SubmissionDoneMessage";
import { skills, skillLabel } from "./SkillCodes";
import { SkillTreeDialog } from "./SkillTree";
import ServerErrorDialog from "./ServerErrorDialog";


const MAX_QUAL_ATTACHMENTS = 4;
const MAX_ATTACHMENT_SIZE = 1024 * 1500;

const schema = yup.object().shape({
  salutation: yup.string().required().label("Salutation"),
  firstName: yup.string().required().max(40).label("First Name"),
  lastName: yup.string().required().max(40).label("Last Name"),
  dateOfBirth: yup.string().required().label("Date of Birth"),
  gender: yup.string().required().label("Gender"),
  address: yup.string().required().max(160).label("Street Address"),
  suburb: yup.string().required().max(40).label("Suburb"),
  city: yup.string().required().max(40).label("City"),
  postCode: yup
    .string()
    .max(4)
    .matches(/^[0-9]+$/, {
      excludeEmptyString: true,
      message: "Post Code must be numeric only",
    })
    .label("Post Code"),
  emailAddress: yup.string().email().required().max(80).label("Email Address"),
  mobileNumber: yup.string().required().max(40).label("Mobile Number"),
  legalWorkStatus: yup.string().required().label("Legal Work Status"),
  legalWorkStatusOther: yup
    .string()
    .max(40)
    .label("Visa Type")
    .when("legalWorkStatus", (status, schema) =>
      status === "OTHER" ? schema.required() : schema
    ),
  visaExpiryDate: yup
    .string()
    .label("Visa Expiry Date")
    .when("legalWorkStatus", (status, schema) =>
      ["VISAHOLDER", "STUDENTVISAHOLDER", "OTHER"].includes(status)
        ? schema.required()
        : schema
    ),
    visaFile: yup
    .mixed()
    .test(
      "size",
      "Attachment must be smaller than 1.5MB",
      (value) => !(value && value[0]) || value[0].size < MAX_ATTACHMENT_SIZE
    ),
  cvFile: yup
    .mixed()
    .test(
      "size",
      "Attachment must be smaller than 1.5MB",
      (value) => !(value && value[0]) || value[0].size < MAX_ATTACHMENT_SIZE
    ),
  qualFile: yup
    .array()
    .of(
      yup
        .mixed()
        .test(
          "size",
          "Attachment must be smaller that 1.5MB",
          ({ value }) =>
            !(value && value[0]) || value[0].size < MAX_ATTACHMENT_SIZE
        )
    )
    .max(
      MAX_QUAL_ATTACHMENTS,
      ({ max }) => `Too many attachments! Maximum ${max}`
    )
    .label("Qualification File"),
    certFile: yup
    .array()
    .of(
      yup
        .mixed()
        .test(
          "size",
          "Attachment must be smaller that 1.5MB",
          ({ value }) =>
            !(value && value[0]) || value[0].size < MAX_ATTACHMENT_SIZE
        )
    )
    .label("Licence and Certification Files"),    
  hasMedicalDeclaration: yup
    .string()
    .nullable()
    .required()
    .label("Medical Declaration"),
  medicalDeclaration: yup
    .string()
    .label("Medical Declaration Details")
    .when("hasMedicalDeclaration", (status, schema) =>
      status === "yes" ? schema.required() : schema
    ),
  hasCriminalConviction: yup
    .string()
    .nullable()
    .required()
    .label("Criminal Conviction"),
  hasDiversionOrder: yup
    .string()
    .nullable()
    .required()
    .label("Diversion Order"),
  awaitingHearing: yup.string().nullable().required().label("Awaiting Hearing"),
  privacyActConsent: yup
    .string()
    .nullable()
    .required()
    .label("Privacy Act Consent"),
  authoriseReference: yup
    .boolean()
    .required()
    .oneOf(
      [true],
      "Please confirm that you have provided accurate information"
    )
    .label("Referee Contact Authorisation"),
  declarationOfAccuracy: yup
    .boolean()
    .required()
    .label("Declaration of Accuracy")
    .oneOf(
      [true],
      "Please confirm that you have provided accurate information"
    ),
  recaptcha: yup.string().nullable().required().label("ReCaptcha Verification"),
});

function isRequired(field) {
  // if (field === "hasMedicalDeclaration") {
  //   console.log(field);
  //   console.log(schema.fields[field]._exclusive);

  // }
  return (
    (schema.fields[field] && schema.fields[field].exclusiveTests.required) ||
    false
  );
}

// type FormValues = {
//     salutation: String,
//     firstName : String,
//     lastName: String,
//   }

const Error = (props) => {
  //return props.error === undefined ? <p>OK</p> : <p>===>>> {props.error.message}</p>
  return (
    props.error ? 
    <div className={`font-bold italic animate-pulse text-red-600`}>
      {props.error && props.error.message}
    </div> : <></>
  );
};

const Label = (props) => {
  return (
    <span>
      <label {...props} className="mb-2  text-grey-darkest" />
      {(props.forcestar || isRequired(props.htmlFor)) && (
        <span className="text-red-600">*</span>
      )}
    </span>
  );
};

const FieldSet = (props) => {
  return <fieldset {...props} className="border-t-2 pt-3" />;
};

const Legend = (props) => {
  return <legend {...props} className="uppercase font-bold text-lg" />;
};

const FormFlexer = (props) => {
  return <div {...props} className="sm:flex flex-row flex-wrap" />;
  
  // className="flex flex-row flex-wrap space-x-4" />;
};

const FormGroup = (props) => {
  return <div {...props} className="flex flex-col mb-4" />;
  //container w-1/2
};

const RegoForm = () => {
  const recaptchaRef = React.createRef();

  const onSubmit = (data, e) => {
    //data.skills = data.skills.map(x => x.value);
    console.log("THE DATA", data);
    const transformed = {
      ...data,
      recaptcha: null,
      skills: data.skills ? Object.keys(data.skills).filter((x) => data.skills[x]) : [],
    };
    
    setSubmissionError(null);
    setSubmissionDialogOpen(true);

    console.log("TRANSFORMED", transformed);

    axios
      .post(
        "/submission",
        serialize(transformed,
          { nullsAsUndefineds: true, indices: true }
        ),
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "content-Type": "multipart/form-data",
            "g-recaptcha-response": data.recaptcha,
          },
        }
      )
      .then((response) => {
        setSubmissionDone(true);
        setSubmissionDialogOpen(false);
        console.log("SUBMITTED:", response);
      })
      .catch((error) => {
        console.log("ERROR:", error);
        setSubmissionDialogOpen(false);
        setServerErrorOpen(true);
        setSubmissionError(error);
      });
      console.log("Submitting...");
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const prevJobArray = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "prevJob", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const qualFileArray = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "qualFile", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const certFileArray = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "certFile", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);
  const [submissionDone, setSubmissionDone] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [skillTreeOpen, setSkillTreeOpen] = useState(false);
  const [serverErrorOpen, setServerErrorOpen] = useState(false);

  const watchFirstName = watch("firstName", "");
  const watchHasMedicalDeclaration = watch("hasMedicalDeclaration", "");
  const watchLegalStatus = watch("legalWorkStatus", "");
  const watchSkills = watch("skills");
  // const watchHasCriminalConvication = watch("hasCriminalConviction", "");
  // const watchAwaitingHearing = watch("awaitingHearing", "");
  // const watchHasDiversionOrder = watch("hasDiversionOrder", "");
  // const watchPrivacyActConsent = watch("privacyActConsent", "");
  // const watchDeclarationOfAccuracy = watch("declarationOfAccuracy", "");
  // const watchDeclaration = watch("declarationOfAccuracy");

  //const [skills, setSkills] = useState({ selectedOption: [] });

  // useEffect(() => {
  //   register("skills");
  // }, []);

  useEffect(() => {
    if (watchFirstName === "rware") {
      fillForm();
    }
  }, [watchFirstName]);

  const fillForm = () => {
    reset({
      salutation: "Mr",
      firstName: "Test",
      lastName: "Person",
      dateOfBirth: "2000-01-02",
      gender: "M",
      address: "21 Jump Street",
      suburb: "Fake Town",
      city: "Not Real",
      postCode: "1234",
      emailAddress: "test@test.com",
      mobileNumber: "12345678",
      legalWorkStatus: "NZCITIZEN",
      hasMedicalDeclaration: "no",
      hasCriminalConviction: "yes",
      hasDiversionOrder: "no",
      awaitingHearing: "no",
      privacyActConsent: "yes",
      declarationOfAccuracy: true,
      //skills: [],
      prevJob: [
          {
              company: "Weyland Corporation",
              jobTitle: "Pest Controller",
              startDate: "2010-01-01",
              endDate: "2020-02-02"
        },
        {
            company: "Omni Consumer Products",
            jobTitle: "Inhuman Resources",
            startDate: "2020-02-02",
            endDate: "2030-03-03"
      },
      ],
    refName: "Ebenezer Scrooge",
    refCompany: "Scrooge & Marley",
    refPosition: "Boss",
    refPhone: "12345678",
    authoriseReference: true
    });
  };

  // const PickGroup = (props) => {
  //     return <div className="flex flex-row flex-wrap my-4" >
  //         {props.options.map(x =>
  //                   <SkillPick key={x.value} value={x.value} label={x.label}/>
  //               )}
  //               </div>
  // }

  // const SkillPick = (props) => {

  //   //console.log(watchSkills);
  //   const isChecked = () => watchSkills.includes(props.value);

  //   return <label
  //   className={"transition-colors mr-1 mb-2 px-4 py-2 rounded-full "
  //    + (isChecked() ? " bg-brand-400 hover:bg-brand-500 " : " bg-gray-300 hover:bg-gray-400 ")}>

  //   <input
  //     className="hidden form-checkbox mr-3 focus:outline-none focus:ring-4 focus:ring-brand-200"
  //     type="checkbox"
  //     name="skills"
  //     {...register("skills")}
  //     value={props.value}
  //   />
  //   {isChecked()
  //   ? <FontAwesomeIcon icon={faCheckCircle} className="text-white" />
  //   : <FontAwesomeIcon icon={faCircle} className="text-white" />}
  //     <span className="ml-2">{props.label}</span>
  //   </label>;

  // }

  return (
    <>
      <SubmissionDialog
        isOpen={submissionDialogOpen}
        setIsOpen={setSubmissionDialogOpen}
      />

      <ServerErrorDialog isOpen={serverErrorOpen} setIsOpen={setServerErrorOpen} />

      {submissionDone ? (
        <SubmissionDoneMessage />
      ) : (
        <div className="font-sans flex items-center h-full w-full bg-white">
          <div className="border-black border-2 w-full bg-white shadow-lg m-4 md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto">
            <div className="bg-black w-full p-2 h-10 text-white font-bold">
              REGISTER YOUR INTEREST
            </div>
            <div className="p-x-8 p-b-8 m-4">
              <img src="Link2 Recruitment Logo.png" className="mb-6 w-1/2" />
              {/* <h1 className="text-2xl font-bold">Register your interest</h1> */}
              {/* <pre>{JSON.stringify(watchAll)}</pre> */}
              <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
                <FieldSet>
                  <Legend>Your Details</Legend>
                  <FormFlexer>
                    <FormGroup>
                      <Label htmlFor="salutation">Salutation:</Label>
                      <MySelect
                        register={register}
                        name="salutation"
                        id="salutation"
                      >
                        <option></option>
                        <option value="Miss">Miss</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Ms">Ms</option>
                        <option value="Dr">Dr</option>
                      </MySelect>
                      <Error error={errors.salutation} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="firstName">First Name:</Label>
                      <Input
                        register={register}
                        name="firstName"
                        id="firstName"
                      />
                      <Error error={errors.firstName} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="lastName">Last Name:</Label>
                      <Input
                        register={register}
                        name="lastName"
                        id="lastName"
                      />
                      <Error error={errors.lastName} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="dateOfBirth">Date of Birth:</Label>
                      <Input
                        register={register}
                        name="dateOfBirth"
                        type="date"
                        id="dateOfBirth"
                      />
                      <Error error={errors.dateOfBirth} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="gender">Gender:</Label>
                      <MySelect register={register} name="gender" id="gender">
                        <option value=""></option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="N">Non-identifying</option>
                        <option value="T">Trans-gender</option>
                      </MySelect>
                      <Error error={errors.gender} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="cvFile">CV Upload:</Label>
                      <div
                      className="mb-1 flex flex-row space-x-4 items-center"
                    >
                      
                        <FileUpload name="cvFile" register={register} clear={() => setValue("cvFile", null)}/>
                      
                      <Error error={errors.cvFile} />
                      
                    </div>
                      {/* ("cvFile", {validate: value => !(value && value[0]) || ( value[0].size < 4194304) || "Attachment must not be larger than 4MB"} ) */}
                      <Error error={errors.cvFile} />
                    </FormGroup>
                  </FormFlexer>
                </FieldSet>

                <FieldSet>
                  <Legend>Contact Information</Legend>

                  <FormFlexer>
                    <FormGroup>
                      <Label htmlFor="address">Street Address:</Label>
                      <Input register={register} name="address" id="address" />
                      <Error error={errors.address} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="suburb">Suburb:</Label>
                      <Input register={register} name="suburb" id="suburb" />
                      <Error error={errors.suburb} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="city">City:</Label>
                      <Input register={register} name="city" id="city" />
                      <Error error={errors.city} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="postCode">Post Code:</Label>
                      <Input
                        register={register}
                        name="postCode"
                        id="postCode"
                      />
                      <Error error={errors.postCode} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="emailAddress">Email Address:</Label>
                      <Input
                        register={register}
                        type="email"
                        name="emailAddress"
                        id="emailAddress"
                      />
                      <Error error={errors.emailAddress} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="mobileNumber">Mobile Number:</Label>
                      <Input
                        register={register}
                        type="tel"
                        name="mobileNumber"
                        id="mobileNumber"
                      />
                      <Error error={errors.mobileNumber} />
                    </FormGroup>
                  </FormFlexer>
                </FieldSet>

                <FieldSet>
                  <Legend>Legal Work Status</Legend>
                  <FormFlexer>
                    <FormGroup>
                      <Label htmlFor="legalWorkStatus">Legal Status</Label>
                      <MySelect register={register} name="legalWorkStatus">
                        <option value=""></option>
                        <option value="NZCITIZEN">NZ Citizen</option>
                        <option value="NZRESIDENT">NZ Resident</option>
                        <option value="VISAHOLDER">Visa Holder</option>
                        <option value="STUDENTVISAHOLDER">
                          Student Visa Holder
                        </option>
                        <option value="OTHER">Other</option>
                      </MySelect>
                      <Error error={errors.legalWorkStatus} />
                    </FormGroup>

                    {watchLegalStatus === "OTHER" && (
                      <FormGroup>
                        <Label htmlFor="legalWorkStatusOther" forcestar>
                          If other, please indicate visa type:
                        </Label>
                        <Input
                          register={register}
                          name="legalWorkStatusOther"
                          id="legalWorkStatusOther"
                        />
                        <Error error={errors.legalWorkStatusOther} />
                      </FormGroup>
                    )}

                    {["VISAHOLDER", "STUDENTVISAHOLDER", "OTHER"].includes(
                      watchLegalStatus
                    ) && (
                      <>
                        <FormGroup>
                          <Label htmlFor="visaExpiryDate" forcestar>
                            Visa expiry date:
                          </Label>
                          <Input
                            register={register}
                            type="date"
                            name="visaExpiryDate"
                            id="visaExpiryDate"
                          />
                          <Error error={errors.visaExpiryDate} />
                        </FormGroup>

                        <FormGroup>
                        <Label htmlFor="visaFile">
                          Attach a copy of your visa/passport:
                        </Label>
                        <div
                      className="mb-1 flex flex-row space-x-4 items-center"
                    >
                      
                        <FileUpload name="visaFile" register={register} clear={() => setValue("visaFile", null)} />
                      
                      <Error error={errors.visaFile} />
                      
                      
                    </div>
                      </FormGroup>
                      </>
                    )}
                  </FormFlexer>
                </FieldSet>

                <FieldSet>
                <Legend>Skills, Qualifications and Licences</Legend>
                <div className="flex flex-col mb-4">
                  <Label htmlFor="skills">
                    Choose all skills and qualifications that apply to you:
                  </Label>

                  
                  <div className="rounded-md bg-brand-100 flex flex-row p-2 flex-wrap">

                    <div onClick={() => setSkillTreeOpen(true)} className="rounded-full bg-brand-400 hover:bg-brand-500 text-white p-2 pr-3 mr-2 mb-2"><FontAwesomeIcon icon={faPlusCircle} className="text-white mr-2"/>Add Skills</div>
                    
                    {watchSkills && Object.keys(watchSkills).filter(x => watchSkills[x]).map(x => (
                      <div key={x} className="rounded-full mr-2 mb-2 p-2 pr-3 bg-white"><FontAwesomeIcon icon={faPlusCircle} className="text-white mr-2 hidden"/>{skillLabel(x, skills)}</div>
                    ))}
                  </div>



                  <Error error={errors.skills} />
                  {/* <Controller
                  control={control}
                  name="skills"
                  render={({field}) => (
                    <ReactSelect
                    options={skills}
                    isMulti
                    defaultValue=""
                    {...field}
                    />
                  )} /> */}
                </div>

                <div className="bg-brand-100 rounded-md px-2 pt-3 my-2 pb-1 shadow">
                <h3 className="font-bold mb-1">Qualification uploads</h3>
                <FormGroup >
                  {qualFileArray.fields.map((field, index) => (
                    <div
                      key={index}
                      className="mb-1 flex flex-row space-x-4 items-center"
                    >
                      
                        <FileUpload register={register} name={`qualFile.${index}.value`} clear={() => qualFileArray.remove(index)}/>
                      
                        <Error
                          error={errors.qualFile && errors.qualFile[index]}
                        />
                     
                    </div>
                  ))}
                  <Error error={errors.qualFile} />
                  {qualFileArray.fields.length < MAX_QUAL_ATTACHMENTS && (
                    <div
                      role="button"
                      className="group"
                      onClick={() => qualFileArray.append({ value: "" })}
                    >
                      <FontAwesomeIcon
                        className="mr-4 text-brand-400 group-hover:text-brand-500"
                        icon={faPlusCircle}
                      />
                      {qualFileArray.fields.length === 0
                        ? `Attach a qualification file (up to ${MAX_QUAL_ATTACHMENTS})`
                        : `Attach another qualification file (${
                            MAX_QUAL_ATTACHMENTS - qualFileArray.fields.length
                          } remaining)`}
                    </div>
                  )}
                </FormGroup>
</div>
                
<div className="bg-brand-100 rounded-md px-2 pt-3 my-2 pb-1 shadow">
  <h3 className="font-bold mb-1">Certificate and Licence uploads</h3>
                <FormGroup >
                  {certFileArray.fields.map((field, index) => (
                    <div
                      key={index}
                      className="mb-1 flex flex-row space-x-4 items-center"
                    >
                      <FileUpload register={register} name={`certFile.${index}.value`} clear={() => certFileArray.remove(index)}/>
                        <Error
                          error={errors.certFile && errors.certFile[index]}
                        />
                      
                      
                    </div>
                  ))}
                  <Error error={errors.certFile} />
                  
                    <div
                      role="button"
                      className="group"
                      onClick={() => certFileArray.append({ value: "" })}
                    >
                      <FontAwesomeIcon
                        className="mr-4 text-brand-400 group-hover:text-brand-500"
                        icon={faPlusCircle}
                      />
                      {certFileArray.fields.length === 0
                        ? `Attach a licence or certification file`
                        : `Attach another licence or certification file`}
                    </div>
                    
                  
                </FormGroup>
                </div>
              </FieldSet>

                <FieldSet>
                  <Legend>Employment History</Legend>

                  <FormGroup>
                    {prevJobArray.fields.map((field, index) => (
                        <div key={index}>
                        {index !== 0 && <hr className="mb-4"/>}
                      <div className="mb-4 flex flex-row space-x-4">
                          
                          <div className="flex-auto">
                        <FormFlexer>
                          <FormGroup>
                            <Label htmlFor={`prevJob.${index}.company`}>
                              Company
                            </Label>
                            <Input
                              register={register}
                              name={`prevJob.${index}.company`}
                              id={`prevJob.${index}.company`}
                              defaultValue={field.company}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor={`prevJob.${index}.jobTitle`}>
                              Position/Job Title:
                            </Label>
                            <Input
                              register={register}
                              name={`prevJob.${index}.jobTitle`}
                              id={`prevJob.${index}.jobTitle`}
                              defaultValue={field.jobTitle}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor={`prevJob.${index}.startDate`}>
                              Start Date:
                            </Label>
                            <Input
                              type="date"
                              register={register}
                              name={`prevJob.${index}.startDate`}
                              id={`prevJob.${index}.startDate`}
                              defaultValue={field.startDate}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor={`prevJob.${index}.endDate`}>
                              End Date:
                            </Label>
                            <Input
                              type="date"
                              register={register}
                              name={`prevJob.${index}.endDate`}
                              id={`prevJob.${index}.endDate`}
                              defaultValue={field.endDate}
                            />
                          </FormGroup>

                        </FormFlexer>
                        </div>
                        
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="mr-4 flex-initial text-brand-400 hover:text-brand-500 self-center justify-self-start"
                            onClick={() => prevJobArray.remove(index)}
                          />
                      </div>
                      </div>
                    
                    ))}
                    {prevJobArray.fields.length < 5 && (
                      <div
                        role="button"
                        className="group"
                        onClick={() => prevJobArray.append({ value: "" })}
                      >
                        <FontAwesomeIcon
                          className="mr-4 mr-4 text-brand-400 group-hover:text-brand-500"
                          icon={faPlusCircle}
                        />
                        {prevJobArray.fields.length === 0
                          ? "Add a previous job"
                          : `Add another previous job (up to ${
                              5 - prevJobArray.fields.length
                            } remaining)`}
                      </div>
                    )}
                  </FormGroup>
                </FieldSet>



                <FieldSet>
                  <Legend>Work Reference</Legend>
                  <FormFlexer>

                    <FormGroup>
                        <Label htmlFor="refName">Referee Name:</Label>
                        <Input name="refName" id="refName" register={register}/>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="refCompany">Company:</Label>
                        <Input name="refCompany" id="refCompany" register={register}/>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="refPosition">Referee's Position:</Label>
                        <Input name="refPosition" id="refPosition" register={register}/>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="refPhone">Contact Phone Number:</Label>
                        <Input name="refPhone" id="refPhone" register={register}/>
                    </FormGroup>
                  </FormFlexer>
                  <FormGroup>
                  <div>
                      <Controller
                        control={control}
                        name="authoriseReference"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <span className="group">
                          <Label
                            htmlFor="authoriseReference"
                            ref={ref}
                          >
                            <input
                              className="hidden"
                              id="authoriseReference"
                              type="checkbox"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              name="authoriseReference"
                            />
                            {value === true ? (
                              <>
                                <FontAwesomeIcon
                                  className="text-brand-400 group-hover:text-brand-500"
                                  icon={faCheckSquare}
                                />
                                <span className="ml-3 font-bold">I authorise Link2 Recruitment to contact this referee</span>
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon
                                  className="text-brand-400 group-hover:text-brand-500"
                                  icon={faSquare}
                                />
                                <span className="ml-3">I authorise Link2 Recruitment to contact this referee</span>
                              </>
                            )}
                          </Label>
                          </span>
                        )}
                      />
                    </div>
                    {/* <div>
                      <label htmlFor="authoriseReference">
                        <Input
                          className="hidden focus:outline-none focus:ring-4 focus:ring-brand-200"
                          id="authoriseReference"
                          type="checkbox"
                          register={register}
                          name="authoriseReference"
                        />
                        {watchAuthoriseReference === true ? (
                          <>
                            <FontAwesomeIcon
                              className="text-brand-500"
                              icon={faCheckSquare}
                            />
                            <span className="ml-3 font-bold">I authorise Link2 Recruitment to contact this referee</span>
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              className="text-brand-500"
                              icon={faSquare}
                            />
                            <span className="ml-3">I authorise Link2 Recruitment to contact this referee</span>
                          </>
                        )}
                      </label>
                    </div> */}
                    <Error error={errors.authoriseReference} />
                  </FormGroup>
                </FieldSet>

                {/* <FieldSet>
              <Legend>Questionnaire</Legend>

               

              <p className="italic mb-4">Please select all options which apply to you.</p>

              <label>How did you hear about Link2Services?</label>

              <PickGroup options={advert_options} />

              <label>Which travel options apply to you?</label>

              <PickGroup options={travel_options} />

              <label>Which transport qualifications do you hold?</label>

              <PickGroup options={transport_options} />

              <label>Which of these certificates do you hold?</label>

              <PickGroup options={cert_options} />

              <label>When can you work?</label>

              <PickGroup options={shift_options} />

              <label>How much can you lift?</label>

              <PickGroup options={lift_options} />

              <label>Do you own any of the following personal protective equipment?</label>

              <PickGroup options={ppe_options} />

              <label>Where are you located?</label>

              <PickGroup options={location_options} />

              <label>Which of the following logistics skills and qualifications options apply to you?</label>

              <PickGroup options={logistics_options} /> */}

                {/* 
                 <FormGroup>
                            <Input type="checkbox" name="skills" value="PICKPACK"/><label>Pick and pack</label>
                </FormGroup> 
                <FormFlexer>
                <FormGroup>
                  <label className="skillsLabel">Skills</label>
                  <DropdownTreeSelect
                    mode="hierarchical"
                    onChange={skillsOnChange}
                    id="skills"
                    data={options}
                  />
                </FormGroup>
              </FormFlexer> 
            </FieldSet>  */}

<FieldSet>
                <Legend>Medical</Legend>
                <FormFlexer>
                  <FormGroup>
                    <Label htmlFor="hasMedicalDeclaration">
                    Do you suffer from, or have you ever suffered from any ailment or medical condition 
                    caused by any illness or injury that might impact your ability to carry out any role offered or might be aggravated by any role offered? 
                    </Label>
                    <YesNoRadio
                      control={control}
                      name="hasMedicalDeclaration"
                    />
                    <Error error={errors.hasMedicalDeclaration} />
                  </FormGroup>
                  {watchHasMedicalDeclaration === "yes" && (
                    <div className="w-full">
                      <FormGroup>
                        <Label htmlFor="medicalDeclaration" forcestar>
                          Please specify:
                        </Label>
                        <textarea
                          className="shadow-inner border border-gray-400 rounded-md focus:outline-none focus:ring-4 focus:ring-brand-200 py-2 px-3 text-grey-darkest"
                          rows="3"
                          {...register("medicalDeclaration", {
                            required: true,
                          })}
                          id="medicalDeclaration"
                        />
                        <Error error={errors.medicalDeclaration} />
                      </FormGroup>
                    </div>
                  )}
                </FormFlexer>
              </FieldSet>

              <FieldSet>
                <Legend>Criminal Convictions</Legend>

                <FormGroup>
                  <Label htmlFor="hasCriminalConviction">
                  Do you have any present convictions, not including concealed under the Clean Slate Act?
                  </Label>
                  <YesNoRadio control={control} name="hasCriminalConviction" />
                  <Error error={errors.hasCriminalConviction} />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="hasDiversionOrder">
                  Have you been a subject of a Diversion ordered by the courts?
                  </Label>
                  <YesNoRadio control={control} name="hasDiversionOrder" />
                  <Error error={errors.hasDiversionOrder} />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="awaitingHearing">
                  Are you awaiting the hearing of any charges in a civil or a criminal court of law?
                  </Label>
                  <YesNoRadio control={control} name="awaitingHearing" />
                  <Error error={errors.awaitingHearing} />
                </FormGroup>
              </FieldSet>

              <FieldSet>
                <Legend>Privacy Act Consent</Legend>

                <FormGroup>
                  <Label htmlFor="privacyActConsent">
                  Do you give consent to the company retaining the information contained in this application form for the purpose of considering your suitability for any other position which may arise with this company in the future? 
                  </Label>
                  <YesNoRadio control={control} name="privacyActConsent" />
                  <Error error={errors.privacyActConsent} />
                </FormGroup>
              </FieldSet>

                <FieldSet>
                <Legend>Declaration of Accuracy</Legend>
                <FormFlexer>
                  <FormGroup className="w-full">
                    <Label>
                    By selecting “yes” you are declaring that all the information provided is to the best of your knowledge, true and correct.
If any of this information is found to be misleading then your employment maybe terminated without notice. 

                    </Label>
                    <div>
                      <Controller
                        control={control}
                        name="declarationOfAccuracy"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <span className="group">
                          <Label
                            htmlFor="declarationOfAccuracy"
                            ref={ref}
                          >
                            <input
                              className="hidden"
                              id="declarationOfAccuracy"
                              type="checkbox"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              name="declarationOfAccuracy"
                            />
                            {value === true ? (
                              <>
                                <FontAwesomeIcon
                                  className="text-brand-400 group-hover:text-brand-500"
                                  icon={faCheckSquare}
                                />
                                <span className="ml-3 font-bold">Yes</span>
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon
                                  className="text-brand-400 group-hover:text-brand-500"
                                  icon={faSquare}
                                />
                                <span className="ml-3">Yes</span>
                              </>
                            )}
                          </Label>
                          </span>
                        )}
                      />
                    </div>
                    <Error error={errors.declarationOfAccuracy} />
                  </FormGroup>
                  {/* <pre>{JSON.stringify(watchDeclarationOfAccuracy)}</pre> */}
                </FormFlexer>
              </FieldSet>
                <Controller
                  control={control}
                  render={({ field: { onChange } }) => (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LdxwbIaAAAAAHnD0E7V2O386N8VdZhBED9_6mr5"
                      onChange={onChange}
                      name="recaptcha"
                    />
                  )}
                  name="recaptcha"
                />
                <Error error={errors.recaptcha} />

                {(Object.keys(errors).length !== 0) &&
              <div className={`font-bold italic animate-pulse text-red-600`}>
              Please fix all errors before submitting
              </div>}


                <button
                  role="submit"
                  className="mt-6 px-6 py-3 focus:outline-none focus:ring-4 bg-gradient-to-r hover:bg-gradient-to-l focus:ring-brand-200 from-stops-1 hover:from-stops-3 via-stops-2 to-stops-3 hover:to-stops-1 transition duration-500 text-white font-extralight"
                >
                  SUBMIT FORM
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
                  <SkillTreeDialog
                    isOpen={skillTreeOpen}
                    setIsOpen={setSkillTreeOpen}
                    options={skills}
                    control={control}
                    watchSkills={watchSkills}
                  />
    </>
  );
};

export default RegoForm;
