
const licenceClasses = [
    {value: 'LICC1L', label: 'Class 1 - Car Learners'},
    {value: 'LICC1R', label: 'Class 1 - Car Restricted'},
    {value: 'LICC1', label: 'Class 1 - Car'},
    {value: 'LICC2', label: 'Class 2 - Medium Rigid'},
    {value: 'LICC3', label: 'Class 3 - Medium Combination'},
    {value: 'LICC4', label: 'Class 4 - Heavy Rigid'},
    {value: 'LICC5', label: 'Class 5 - Heavy Combination'},
    {value: 'LICC6', label: 'Class 6 - Motorcycle'}
];

const licenceEndorsements = [
    {value: 'LICENDD', label: 'Endorsement - D - Dangerous Goods'},
    {value: 'LICENDFC1', label: 'Endorsement - F - Forkhoist Class 1 under 18,000kg'},
    {value: 'LICENDFC2', label: 'Endorsement - F - Forkhoist Class 2 over 18,000kg'},
    {value: 'LICENDI', label: 'Endorsement - I - Driving Instructor'},
    {value: 'LICENDO', label: 'Endorsement - O - Driver Testing Officer'},
    {value: 'LICENDP', label: 'Endorsement - P - Commercial Passenger'},
    {value: 'LICENDR', label: 'Endorsement - R - Rollers'},
    {value: 'LICENDT', label: 'Endorsement - T - Self-laying Tracks'},
    {value: 'LICENDV', label: 'Endorsement - V - Vehicle Recovery'},
    {value: 'LICENDW', label: 'Endorsement - W - Wheels (Special-type vehicle)'}
];

const licenseSkillsExperience = [
    {value: 'LICSE1', label: 'Skill - Forkhoist - Counterbalance'},
    {value: 'LICSE2', label: 'Skill - Forkhoist - Stock Picker'},
    {value: 'LICSE3', label: 'Skill - Forkhoist - Reach Truck'},
    {value: 'LICSE4', label: 'Skill - Forkhoist - Container Devanning'},
    {value: 'LICSE5', label: 'Skill - Forkhoist - Pallet Truck'}
];

// const administration = [
//     {value: 'POSADMIN', label: 'Administration'},
//     {value: 'POSCLRIC', label: 'Clerical'},
//     {value: 'POSDTDNTY', label: 'Data Entry'},
//     {value: 'POSREC', label: 'Recruitment'},
//     {value: 'POSTRN', label: 'Training'}
// ];

// const warehousing = [
//     {value: 'POSALLGEN', label: 'All General'},
//     {value: 'POSDEVAN', label: 'Container Devanning'},
//     {value: 'POSGENWRH', label: 'General Warehousing'},
//     {value: 'POSINV', label: 'Inventory'},
//     {value: 'POSMPI', label: 'MPI Accredited'},
//     {value: 'POSOPRTN', label: 'Operations'},
//     {value: 'POSPKPK', label: 'Pick/Packer'},
//     {value: 'POSRFSNR', label: 'RF Scanner'},
//     {value: 'POSLIFT15', label: 'Heavy Lifting 15kg'},
//     {value: 'POSLIFT25', label: 'Heavy Lifting 25kg'},
// ];

// const qualifications = [
//     {value: 'CERTBDEG', label: 'Bachelors Degree'},
//     {value: 'CERTMDEG', label: 'Masters Degree'}
// ];

export const skills = [
    {label:"Licences", options: [
        {label:"Licence Classes", options : licenceClasses.sort(compare)},
        {label:"Licence Endorsements", options: licenceEndorsements.sort(compare)},
        {label:"Licence Skills & Experience", options: licenseSkillsExperience.sort(compare)},
    ]},
    {label: "Skills/Positions", options: [
        { value: "BEVBOTTLE", label: "Machine Operator Bottling" },
        { value: "BEVPROC", label: "Process Worker (beverages)" },
        { value: "FOODCRTWSH", label: "Crate Washers (Food)" },
        { value: "FOODMACH", label: "Machine Operator" },
        { value: "FOODPROC", label: "Process Worker (food)" },
        { value: "GENCLBR", label: "Casual Labourer" },
        { value: "GENCLNR", label: "General Cleaner" },
        { value: "GENLBR", label: "General Labourer" },
        { value: "LABBRUSHROLL", label: "Brush And Roller Painter" },
        { value: "LOGDEVAN", label: "Container Devanning" },
        { value: "LOGPICKPACK", label: "Pick Packer" },
        { value: "LOGSTORASS", label: "Stores Assistant " },
        { value: "LOGSTORLEAD", label: "Store Team Leader" },
        { value: "LOGSTORMAN", label: "Stores Manager" },
        { value: "LOGSTORW", label: "Storeman" },
        { value: "MANGENERAL", label: "General Hand" },
        { value: "MANPAINTMAR", label: "Marine Painter " },
        { value: "MANSPRAY", label: "Spray Painter" },
        { value: "PHARPROC", label: "Process Worker (pharmaceutical)" },
        { value: "PLASPROC", label: "Plastics Process Operator" },
        { value: "TECEST", label: "Technical Estimator" },
    ].sort(compare)},
    {label: "Shifts", options: [
        { value: "SHIFTPM", label: "Available for Afternoon shifts"},
        { value: "SHIFTDUAL", label: "Available for AM and PM shifts"},
        { value: "SHIFTNIGHT", label: "Available for Night shifts" },
        { value: "SHIFTNOTSUN", label: "Available Monday to Saturday"},
        { value: "SHIFTAM", label: "Available for Morning shifts" },
        { value: "SHIFTMF", label: "Available to work ONLY weekdays"},
        { value: "SHIFTWKEND", label: "Available to work weekends"},
        { value: "SHIFT12", label: "Can work 12 hour shift" },
    ].sort(compare)}

];

function compare( a, b ) {
    if ( a.label < b.label ){
      return -1;
    }
    if ( a.label > b.label ){
      return 1;
    }
    return 0;
  }

export const skillLabel = (skillCode, skills) => {
    

    for (let index = 0; index < skills.length; index++) {
        const element = skills[index];
        
        if (element.value === skillCode) return element.label;
        if (element.options) {
            const result = skillLabel(skillCode, element.options);
            if (result) return result;
        }
    }
    
    return null;
    
}
