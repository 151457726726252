import React, { Component } from 'react';
import { Layout } from './components/Layout';
import RegoForm from './components/RegoForm';

import './index.css'



const App = () => {
    const displayName = App.name;
    
        return (
            <Layout>
                <RegoForm />
            </Layout>)
}

export default App;
