import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckSquare,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "@headlessui/react";

const Node = ({ value, label, options, watchSkills, control }) => {
  const [expanded, setExpanded] = useState(true);

  const numSelected =
    options && watchSkills
      ? options.filter((x) => watchSkills[x.value]).length
      : 0;

  return options === undefined ? (
    <Option val={value} label={label} control={control} />
  ) : (
    <div>
      <div onClick={() => setExpanded(!expanded)}>
        <FontAwesomeIcon
          fixedWidth
          icon={expanded ? faChevronDown : faChevronRight}
        />
        <span className="ml-2">{label}</span>{" "}
        {numSelected > 0 && (
          <span className="text-xs text-italic">({numSelected} selected)</span>
        )}
      </div>
      <div className="ml-7" hidden={!expanded}>
        {options.map((x) => (
          <div key={x.value ?? x.label}>
            <Node {...x} watchSkills={watchSkills} control={control} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Option = ({ label, val, control }) => {
  return (
    <Controller
      control={control}
      name={`skills.${val}`}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <label className="group">
          <input
            type="checkbox"
            hidden={true}
            checked={value}
            onBlur={onBlur}
            onChange={onChange}
            name="skills"
          />
          <FontAwesomeIcon
            className="text-brand-400 group-hover:text-brand-500"
            icon={value ? faCheckSquare : faSquare}
          />
          <span className={`ml-2 ${value ? "font-bold" : "font-normal"}`}>
            {label}
          </span>
        </label>
      )}
    />
  );
};

export const SkillTree = ({ options, control, watchSkills, ...rest }) => {
  return options.map((x) => (
    <Node watchSkills={watchSkills} key={x.label} {...x} control={control} />
  ));
};

export const SkillTreeDialog = ({
  isOpen,
  setIsOpen,
  options,
  control,
  watchSkills,
}) => {
  return (
    <Dialog
			open={isOpen}
			onClose={setIsOpen}
			as="div"
			className={"fixed inset-0 z-10 flex items-center justify-center overflow-auto"}
		>
			<Dialog.Overlay className="fixed inset-0 bg-black w-screen h-screen opacity-50 z-0" />
			<div className="flex flex-col m-4 bg-white w-120 py-8 px-4 z-10 rounded-md shadow-lg max-h-screen">
				<Dialog.Title className="text-3xl">
					Skills, Licences and Qualifications
				</Dialog.Title>
				<Dialog.Description className="text-xl m-2">
					Choose all that apply to you.
				</Dialog.Description>
<div className="overflow-y-scroll shadow-inner">

        <SkillTree
    options={options}
    control={control}
    watchSkills={watchSkills}
    />

    </div>
				<button
					className="mt-6 px-6 py-3 focus:outline-none focus:ring-4 focus:ring-brand-200 bg-brand-400 hover:bg-brand-500 shadow text-white font-extralight rounded-md"
					onClick={() => setIsOpen(false)}
				>
					DONE
				</button>
			</div>
		</Dialog>
      
      );
    };
    
