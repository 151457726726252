import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as faCircleR } from "@fortawesome/free-regular-svg-icons";
import { faDotCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import {Controller} from "react-hook-form";

export const Input = ({ register, name, ...rest }) => {
  return (
    <input
      key={name}
      className="shadow-inner border border-gray-400 bg-white rounded-md focus:outline-none focus:ring-4 focus:ring-brand-200 py-2 px-3 sm:mr-4 text-grey-darkest"
      {...register(name)}
      {...rest}
    ></input>
  );
};

export const MySelect = ({ register, name, ...rest }) => {
  return (
    <select
      key={name}
      className="shadow-inner border  border-gray-400 bg-white rounded-md focus:outline-none focus:ring-4 focus:ring-brand-200 py-2 px-3 sm:mr-4 text-grey-darkest"
      {...register(name)}
      {...rest}
    />
  );
};

export const YesNoRadio = ({ control, name, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <>
          <div key={name} className="flex items-center flex-row" {...rest}>
            <label
              htmlFor={`${name}Yes`}
              className={value === "yes" ? "font-bold group" : "group"}
            >
              <input
                onChange={onChange}
                className="hidden form-radio text-red-500 focus:outline-none focus:ring-4 focus:ring-brand-200"
                type="radio"
                value="yes"
                ref={ref}
                checked={value}
                id={`${name}Yes`}
              />
              {value === "yes" ? (
                <FontAwesomeIcon
                  className="text-brand-400 group-hover:text-brand-500"
                  icon={faDotCircle}
                />
              ) : (
                <FontAwesomeIcon className="text-brand-400 group-hover:text-brand-500" icon={faCircleR} />
              )}
              <span className="ml-3">Yes</span>
            </label>
          </div>
          <div className="flex items-center flex-row">
            <label
              htmlFor={`${name}No`}
              className={value === "no" ? "font-bold" : ""}
            >
              <input
                onChange={onChange}
                onBlur={onBlur}
                className="hidden form-radio text-red-500 focus:outline-none focus:ring-4 focus:ring-brand-200"
                type="radio"
                value="no"
                checked={value}
                id={`${name}No`}
              />
              {value === "no" ? (
                <FontAwesomeIcon
                  className="text-brand-500"
                  icon={faDotCircle}
                />
              ) : (
                <FontAwesomeIcon className="text-brand-500" icon={faCircleR} />
              )}
              <span className="ml-3">No</span>
            </label>
          </div>
        </>
      )}
    />
  );
};

export const FileUpload = ({register, name, clear}) => {

  return     <div className="flex flex-row items-center" >
       {/* <label className="flex-grow">
           <div className="group items-center flex flex-row shadow-inner border border-gray-400 bg-white rounded-md focus-within:outline-none focus-within:ring-4 focus-within:ring-brand-200 py-2 px-3 text-grey-darkest">
           <FontAwesomeIcon icon={faUpload} className="mr-2 text-brand-400 group-hover:text-brand-500"/>{
           value
           ? value.replace(/^.*[\\\/]/, '')
           : "Click to upload"
           }
           </div> */}
           <input
           className="shadow-inner border border-gray-400 bg-white rounded-md focus:outline-none focus:ring-4 focus:ring-brand-200 py-2 px-3 text-grey-darkest w-full"
           type="file" {...register(name)} />

       {/* </label> */}
       {clear && 
       <div className="flex-shrink">
       <FontAwesomeIcon icon={faTrash} className="ml-2 text-brand-400 hover:text-brand-500" onClick={clear}/>
       </div>
       }
     </div>
     //)}
   ///>
}